import BaseServices from "./base_services"
import Axios from "axios";
const RESOURCE_NAME = "/games"

class ConfigServices extends BaseServices {
  constructor(moduleName) {
    super(moduleName);
  }

  getGamesByProviderId(providerId) {
    return Axios.get(`${this.RESOURCE_NAME}/provider/${providerId}?limit=200`)
  }
}

export default new ConfigServices(RESOURCE_NAME);