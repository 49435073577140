import Axios from "axios";
const RESOURCE_NAME = "/users";

export default {
  setDefaultHeader(token) {
    Axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  },
  clearDefaultHeader() {
    Axios.defaults.headers.common = { Authorization: null };
  },

  async createData(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.post(`${RESOURCE_NAME}/new`, data, { headers });
  },

  async signIn(data) {
    return Axios.post(`${RESOURCE_NAME}/signin`, data);
  },

  getAll(page = 1, limit = 10) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${RESOURCE_NAME}`, {
      headers,
      params: { page: page, limit: limit },
    });
  },

  getOne(user_id) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${RESOURCE_NAME}/${user_id}`, { headers });
  },

  updateData(user_id, data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.put(`${RESOURCE_NAME}/${user_id}`, data, { headers });
  },

  deleteData(id) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.delete(`${RESOURCE_NAME}/${id}`, { headers });
  },

  async verifyToken() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    let result = await Axios.patch(
      `${RESOURCE_NAME}/check`,
      { id: localStorage.id },
      { headers }
    );
    console.log("result :::", result);
    return result;
  },

  logout() {
    return { data: "LOGGED OUT" };
  },
};
