<template>
  <div class="drawer-menu" :class="{ 'is-active': isActive }">
    <div class="drawer-menu-content">
      <slot></slot>
    </div>
    <button class="delete" @click="close"></button>
  </div>
</template>

<script>
export default {
  name: "DrawerMenu",
  props: {
    isActive: Boolean,
  },
  methods: {
    close() {
      this.$emit("update:isActive", false);
    },
  },
};
</script>

<style>
.drawer-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 32;
  width: 300px;
  background-color: #000000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.drawer-menu .navbar-item {
  color: white !important;
}

.drawer-menu .navbar-item:hover {
  background-color: rgb(249, 205, 9) !important;
  color: black !important;
}

.drawer-menu.is-active {
  transform: translateX(0);
}

.drawer-menu-content {
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
}

.drawer-items .navbar-item {
  border-bottom: 2px solid #a01414;
}
</style>
