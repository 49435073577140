import Axios from "axios";

export default class BaseService {
  constructor(resourceName) {
    this.RESOURCE_NAME = resourceName;
  }

  setDefaultHeader(token) {
    Axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  clearDefaultHeader() {
    Axios.defaults.headers.common = { Authorization: null };
  }

  async createData(data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.post(`${this.RESOURCE_NAME}/new`, data, { headers });
  }

  getAll(page = 1, limit = 10) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${this.RESOURCE_NAME}`, {
      headers,
      params: { page: page, limit: limit },
    });
  }

  getOne(id) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.get(`${this.RESOURCE_NAME}/${id}`, { headers });
  }

  updateData(id, data) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.put(`${this.RESOURCE_NAME}/${id}`, data, { headers });
  }

  deleteData(id) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    return Axios.delete(`${this.RESOURCE_NAME}/${id}`, { headers });
  }
}
