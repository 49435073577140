<template>
  <div class="progress-bar-container">
    <h4 class="rtp-percent">RTP: {{ progress }}%</h4>
    <div
      class="progress-bar"
      :style="{ width: progress + '%' }"
      :class="progressColor(progress)"
    >
      <div class="candy-stripe"></div>
    </div>
  </div>
</template>

<style>
h4.rtp-percent {
  letter-spacing: normal;
  font-size: 0.9em;
  font-weight: 600;
  color: #2a2a2a;
  position: absolute;
  width: 100%;
  z-index: 3;
  text-align: center;
}

.progress-bar-container {
  width: 100%;
  height: 25px;
  background-color: #b0b0b0;
  border-radius: 10px;
  filter: drop-shadow(2px 4px 4px #b1b1b1);
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  position: relative;
  overflow: hidden;
  transition: width 0.5s ease;
  border-radius: 10px;
}

.progress-bar-green {
  background-color: #058b32;
}

.progress-bar-yellow {
  background-color: #e4ab00;
}

.progress-bar-orange {
  background-color: #ec6a00;
}

.progress-bar-red {
  background-color: #9d1500;
}

.candy-stripe {
  height: 100%;
  width: 200%;
  position: absolute;
  top: 0;
  left: -50%;
  animation: linear move-stripes 0.5s infinite;
}

.candy-stripe:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

@keyframes move-stripes {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(20%);
  }
}
</style>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
    },
  },
  methods: {
    progressColor(progress) {
      if (progress < 30) {
        return "progress-bar-red"; // red
      } else if (progress < 50) {
        return "progress-bar-orange"; // orange
      } else if (progress < 70) {
        return "progress-bar-yellow"; // yellow
      } else {
        return "progress-bar-green"; // green
      }
    },
  },
};
</script>
