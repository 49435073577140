<template>
  <div class="game">
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background" @click="showModal = false"></div>
      <div class="modal-content">
        <div class="box">
          <div class="good-rtp" v-if="currentItem.rtp > 30">
            <h3 class="title">Pola Gacor {{ currentItem.name }}</h3>
            <div class="columns">
              <div class="column"><b>Provider</b></div>
              <div class="column">
                {{ providerData.provider }}
              </div>
            </div>
            <div class="columns">
              <div class="column"><b>Stake</b></div>
              <div class="column">
                {{ currentItem.stake }}
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-12">⚡️POLA REKOMENDASI⚡️️</div>
              <div class="column is-12">
                <div class="popup-content">
                  <div class="row mrtop10">
                    <div style="margin-top: -18px; position: absolute">
                      <h1 style="color: #000000; font-size: 45px">
                        <b></b>
                      </h1>
                    </div>
                    <div style="color: #000000">
                      <h6 style="margin-bottom: 4px; color: #000000">
                        <b>1). Step Testing Mesin Slot</b>
                      </h6>
                      <label style="margin-bottom: 0px" id="tips_number_1">{{
                        currentItem.testing
                      }}</label
                      >X . Mulai dengan bet paling kecil.
                    </div>
                  </div>
                  <div class="row mrtop10">
                    <div style="margin-top: -18px; position: absolute">
                      <h1 style="color: #000000; font-size: 45px">
                        <b></b>
                      </h1>
                    </div>
                    <div style="color: #000000">
                      <h6 style="margin-bottom: 4px; color: #000000">
                        <b>2). Step Naikkan SPIN</b>
                      </h6>
                      <label style="margin-bottom: 0px" id="tips_number_2">{{
                        currentItem.speed
                      }}</label
                      >X Spin cepat (AUTO/TURBO)
                    </div>
                  </div>
                  <div class="row mrtop10">
                    <div style="margin-top: -18px; position: absolute">
                      <h1 style="color: #000000; font-size: 45px">
                        <b></b>
                      </h1>
                    </div>
                    <div style="color: #000000">
                      <h6 style="margin-bottom: 4px; color: #000000">
                        <b>3). Step Stop SPIN</b>
                      </h6>
                      <label style="margin-bottom: 0px" id="tips_number_3">{{
                        currentItem.manual
                      }}</label
                      >X Manual Spin tanpa Ceklis
                    </div>
                  </div>
                  <div class="row mrtop10">
                    <div style="margin-top: -18px; position: absolute">
                      <h1 style="color: #000000; font-size: 45px">
                        <b></b>
                      </h1>
                    </div>
                    <div style="color: #000000">
                      <strong style="margin-bottom: 4px; color: #102f4b">
                        <b
                          >- Step Terakhir - BELI FITUR (BILA GAME BISA DI
                          BELI)</b
                        > </strong
                      ><br />
                      <strong style="margin-bottom: 4px; color: #00aeff">
                        <b>Jika tidak mendapatkan (FREESPIN)</b>
                      </strong>
                    </div>
                    <div class="row mrtop10">
                      <div style="margin-top: -18px; position: absolute">
                        <h1 style="color: #000000; font-size: 45px">
                          <b></b>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="popup-label" style="background: #102f4b; color: #fff">
                <p>
                  <b>UTAMAKAN MENGGUNAKAN POLA GACOR MASING-MASING </b>
                  <label style="margin-bottom: 0px"
                    >Pastikan Nyalakan Fitur Ganda Jika Ada, Guna Mempercepat
                    datangnya SCATTER</label
                  >
                </p>
                <br />
                <div>
                  <div class="columns">
                    <div class="column">
                      <a
                        class="button is-primary is-fullwidth"
                        :href="links.REGISTER_LINK"
                      >
                        <strong>Daftar</strong>
                      </a>
                    </div>
                    <div class="column">
                      <a
                        class="button is-light is-fullwidth"
                        :href="links.LOGIN_LINK"
                      >
                        Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bad-rtp" v-if="currentItem.rtp <= 30">
            <h3 class="title">RTP {{ currentItem.name }} sedang rendah</h3>
            <img
              src="https://139.59.115.166/Images/Dontplay/no-no-no-nope.gif"
            />
            <p class="text-red"><b>RTP slot ini sedang rendah</b></p>
            <p>
              Silahkan pilih permainan lain dengan RTP yang lebih baik agar
              peluang menang semakin besar
            </p>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="showModal = false"
      ></button>
    </div>
    <div class="columns" style="padding: 2px 20px 2px">
      <div class="column narrative mnc-gradient">
        <h3 class="title is-3">
          RTP Live {{ providerData.provider.toUpperCase() }} Terkini
        </h3>
        <p>
          Mau menang JACKPOT main slot
          <strong>{{ providerData.provider.toUpperCase() }}</strong
          >?
        </p>
        <p>
          Jackpot pasti <strong>GACOR</strong> dan <strong>MAXWIN</strong>
          <br />
          Jangan lupa simak update RTP live terbaru dari slot gacor
          <a href="https://mnctoto.com/">MNCTOTO</a> . Kami selalu update setiap
          harinya untuk memberikan bocoran RTP slot serta pola main slot.
          Penasaran kan slot apa yang lagi gacor dengan RTP tinggi dari provider
          {{ providerData.provider.toUpperCase() }}?
        </p>
        <p>
          Kalau kamu penasaran dan kamu mau menang main slot, mimin mau infoin
          kalo disini mimin bisa kasih solusi untuk semua permasalahan kamu di
          dunia slot online. Berikut daftar RTP Live
          {{ providerData.provider.toUpperCase() }} pada
          {{
            new Date().toLocaleString("id-ID", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }}:
        </p>
      </div>
    </div>
    <div
      class="columns is-desktop is-mobile is-multiline is-flex"
      style="padding: 2px 10px 2px"
    >
      <div class="column is-12">
        <img :src="links.BANNER_URL" alt="" style="width: 100%" />
      </div>
    </div>
    <div
      class="columns game-columns is-1-mobile is-3-desktop is-desktop is-mobile is-multiline is-flex"
      style="padding: 2px 10px 2px"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        class="column is-2-desktop is-3-tablet is-6-mobile"
        @click="doShowModal(item)"
      >
        <div class="card is-flex-grow-1 game-card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img :src="item.picture" />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <ProgressBar :progress="item.rtp" />
            </div>

            <div class="content">
              <b>{{ item.name }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ProgressBar from "@/components/ProgressBar.vue";
import GameServices from "@/services/game_services";
import ProviderServices from "@/services/provider_services";

import Config_Service from "@/services/config_services";
import { getWithExpiry, setWithExpiry } from "@/utils/localStorageUtils";

export default {
  name: "Home",
  components: {
    ProgressBar,
  },
  setup() {
    const route = useRoute();
    const providerData = ref({ provider: "", icon: "", id: 0 });
    const data = ref({});

    const showModal = ref(false);
    const currentItem = ref({
      id: 2,
      provider: "JOKER",
      name: "Wild Booster",
      gambar: "wild-booster.jpg",
      number: 88,
      stake: "BEBAS",
      testing: 30,
      speed: 20,
      manual: 5,
    });

    const links = ref({
      REGISTER_LINK: "",
      LOGIN_LINK: "",
      BANNER_URL: "",
    });

    watch(
      () => route.params.name,
      () => {
        mountScreen();
      }
    );

    const mountScreen = async () => {
      await loadDataFromServer();
    };

    onMounted(async () => {
      mountScreen();
      links.value.REGISTER_LINK = await getWebsiteConfig("REGISTER_LINK");
      links.value.LOGIN_LINK = await getWebsiteConfig("LOGIN_LINK");
      links.value.BANNER_URL = await getWebsiteConfig("GAME_BANNER");
    });

    const loadDataFromServer = async () => {
      let tmpGameData = await ProviderServices.getOne(route.params.name);
      providerData.value = tmpGameData.data.data;

      let response = await GameServices.getGamesByProviderId(route.params.name);
      data.value = response.data.data.rows;
    };

    const getWebsiteConfig = async (key) => {
      let storedValue = await getWithExpiry(key);
      if (storedValue) {
        return storedValue;
      } else {
        let fetchedValue = await Config_Service.getOne(key);
        setWithExpiry(key, fetchedValue.data.value);
        return fetchedValue.data.value;
      }
    };

    const doShowModal = (data) => {
      currentItem.value = data;
      showModal.value = true;
    };
    return {
      links,
      route,
      providerData,
      data,
      showModal,
      currentItem,
      doShowModal,
    };
  },
};
</script>

<style>
.game {
  padding-top: 30px;
}

.game-card {
  margin-bottom: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.game .columns {
  padding: 2px 10px 2px;
}

.game-columns {
  padding: -10px;
}

.narrative {
  background: #ffffff;
}

.narrative p {
  text-align: left;
}
</style>
