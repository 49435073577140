<template>
  <router-link :to="{ name: 'AdminSetting' }" class="navbar-item">
    Konfigurasi Website
  </router-link>

  <router-link :to="{ name: 'AdminProvider' }" class="navbar-item">
    Atur Provider Game
  </router-link>

  <router-link :to="{ name: 'AdminGame' }" class="navbar-item">
    Atur Game RTP
  </router-link>

  <router-link :to="{ name: 'AdminGameDemo' }" class="navbar-item">
    Atur Demo Game
  </router-link>

  <router-link :to="{ name: 'AdminAdmin' }" class="navbar-item">
    Atur Admin
  </router-link>
</template>

<script>
export default {};
</script>

<style></style>
