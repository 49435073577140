<template>
  <div class="navbar is-fixed-bottom is-hidden-desktop" id="bottom-nav-bg">
    <div class="navbar-end bottom-nav">
      <template v-for="(item, index) in bottomNavItems" :key="index">
        <router-link v-if="item.path" :to="{ path: item.path.name }">
          <div class="navigation-buttons">
            <span class="icon is-large"><i :class="item.icon"></i></span>
            <span>{{ item.title }}</span>
          </div>
        </router-link>
        <a v-else-if="item.href" :href="item.href" class="item">
          <div class="navigation-buttons">
            <span class="icon is-large"><i :class="item.icon"></i></span>
            <span>{{ item.title }}</span>
          </div>
        </a>
        <span
          v-if="index < bottomNavItems.length - 1"
          class="navbar-item-separator"
        ></span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomNav",
  props: {
    bottomNavItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.navbar .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navbar .bottom-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-item-separator {
  display: inline-block;
  height: 2rem;
  margin: 0 0.5rem;
  border-right: 1px solid #dbdbdb;
}

.navigation-buttons {
  display: flex;
  flex-direction: column;
  margin: 0px 0.5em 0.5em;
  color: #ffc803 !important;
}

.navigation-buttons:hover {
  color: #ffffff !important;
}

#bottom-nav-bg {
  background: rgb(0, 0, 0);
  border-radius: 20px;
  margin: 20px;
}
</style>
