import BaseServices from "./base_services";
import Axios from "axios";
const RESOURCE_NAME = "/config";

class ConfigServices extends BaseServices {
  constructor(moduleName) {
    super(moduleName);
  }

  async getIcon() {
    let response = await Axios.get(`${this.RESOURCE_NAME}/ICON`);
    return response.data.value;
  }

  async clearSingleCache(key) {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    let response = await Axios.put(
      `${this.RESOURCE_NAME}/clear/${key}`,
      {},
      { headers }
    );
    return response;
  }

  async FlushAllCache() {
    const headers = { Authorization: `Bearer ${localStorage.token}` };
    let response = await Axios.put(
      `${this.RESOURCE_NAME}/flush/flushall`,
      {},
      { headers }
    );
    return response;
  }
}

export default new ConfigServices(RESOURCE_NAME);
