import { createRouter, createWebHistory } from "vue-router";
import Game from "../views/Game.vue";
import User_Service from "../services/user_services";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/game/:name",
    name: "Game",
    component: Game,
    meta: {
      title: "Live RTP Games",
    },
  },
  {
    path: "/demo/:id",
    name: "GameDemoView",
    component: () => import("../views/DemoGames/DemoGameView.vue"),
    meta: {
      title: "Demo Game",
    },
  },
  {
    path: "/demo",
    name: "GameDemoList",
    component: () => import("../views/DemoGames/DemoGameList.vue"),
    meta: {
      title: "Demo Game",
    },
  },
  {
    path: "/config/admin-login",
    name: "AdminLogin",
    component: () => import("../views/Admin/Admin_Login/admin_login_main.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/config/admin-setting",
    name: "AdminSetting",
    component: () =>
      import("../views/Admin/Admin_Setting/admin_setting_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Website",
    },
  },
  {
    path: "/config/admin-game",
    name: "AdminGame",
    component: () => import("../views/Admin/Admin_Game/admin_game_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Game",
    },
  },
  {
    path: "/config/admin-game-demo",
    name: "AdminGameDemo",
    component: () =>
      import("../views/Admin/Admin_Game_Demo/admin_game_demo_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Demo Game",
    },
  },
  {
    path: "/config/admin-admin",
    name: "AdminAdmin",
    component: () => import("../views/Admin/Admin_Admin/admin_admin_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Admin",
    },
  },
  {
    path: "/config/admin-provider",
    name: "AdminProvider",
    component: () =>
      import("../views/Admin/Admin_Provider/admin_provider_main.vue"),
    meta: {
      requiresAuth: true,
      title: "Atur Provider",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.meta.requiresAuth &&
    !localStorage.token &&
    User_Service.verifyToken()
  ) {
    next("/");
  } else if (to.meta.requiresUnauth && !!localStorage.token) {
    next("/");
  } else {
    next();
  }
});

export default router;
